


import {
    Component,
    Vue
} from "vue-property-decorator";
import Axios from "axios";
import {
    BASE_API_URL
} from "@/config";
import {
    VendorInfoModel
} from "@/models/vendor-info.model";
import {
    LenderModel
} from "@/models/lender.model";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
import {
    mixins
} from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";

@Component
export default class LenderInfo extends mixins(CommonMixin) {
  public brokerLenders = new Array<LenderModel>();
  public inProcess = false;
  public duplicateEmails: string[] = [];
  public emptyLenderNames = [];
  public previousLendersToRemove = [];
  public previousInProcess: boolean = false;
  public firstNameError=false;
  public isCustomLenderApproved = false;

  public lenderInfo: any = {
    lenderNameAndId: {}
  };
  public defaultLabelName = "Add Lender";
  public defaultButtonName = "SAVE LENDER";
  public lenderList: any = [];
  public searchByType = null;
  public searchString = "";
  public addedLenders: any = [];
  public hasErrorMessage = false;
  public selectLender: boolean = false;
  public phoneError = false;
  public errorMessage = null;
  public lenderLoader = false;
  public dotLoader = false;
  public editLenderData: any = {};
  public lenderId = null;
  public userType = null;
  public delete = "yes";
  public phoneNumber: any = {
    first: null,
    middle: null,
    last: null
  };

  public get sessionObject() {
    return this.$store.state.sessionObject;
  }

    public async validateAndSubmit() {
        try {
            this.inProcess = true;
            let formValid = await this.$validator.validateAll();
            if (!formValid || this.emptyLenderNames.length > 0) {
                this.$snotify.error("Please fix errors first.");
                this.inProcess = false;
                return;
            }

            for (let i = 0; i < this.brokerLenders.length; i++) {
                if (this.brokerLenders[i].accountExecutiveLastName != "" && this.brokerLenders[i].accountExecutiveFirstName == "") {
                    this.inProcess = false;
                    this.firstNameError = true
                    return
                }
            }
            let brokerLenders = this.brokerLenders.filter(e => {
                if (
                    this.$userType == "MLO" &&
                    e.brokerlenderReferenceId &&
                    (e.addedByUserType == "Broker" || e.addedByUserType == "Super Admin")
                ) {
                    return e["userName"] || e["password"];
                } else {
                    return (
                        e["lenderName"] != "" ||
                        e["lenderWebSite"] != "" ||
                        e["userName"] != "" ||
                        e["password"] != "" ||
                        e["accountExecutiveFirstName"] != "" ||
                        e["accountExecutiveLastName"] != "" ||
                        e["phone"] != "" ||
                        e["email"] != ""
                    );
                }
            });

            if (brokerLenders.length > 0 || this.previousLendersToRemove.length > 0) {
                this.brokerLenders.forEach(bl => {
                    if (bl.lenderName) {
                        bl.lenderName =
                            bl.lenderName.charAt(0).toUpperCase() +
                            bl.lenderName.substring(1).toLowerCase();
                    }
                    if (bl.accountExecutiveFirstName) {
                        bl.accountExecutiveFirstName =
                            bl.accountExecutiveFirstName.charAt(0).toUpperCase() +
                            bl.accountExecutiveFirstName.substring(1).toLowerCase();
                    }
                    if (bl.accountExecutiveLastName) {
                        bl.accountExecutiveLastName =
                            bl.accountExecutiveLastName.charAt(0).toUpperCase() +
                            bl.accountExecutiveLastName.substring(1).toLowerCase();
                    }
                    bl.email = bl.email.toLowerCase();
                });

                this.brokerLenders.forEach(bl => {
                    bl.addedBy = this.$userId;
                    bl["addedByUserType"] = this.userType;
                    bl.brokerId = this.$brokerId;
                });

                let response = await Axios.post(
                    BASE_API_URL + "broker/saveBrokerLenders", {
                        lenders: brokerLenders,
                        brokerId: this.$brokerId,
                        lendersToRemove: this.previousLendersToRemove,
                        userId: this.$userId,
                        userType: this.userType
                    });

                if (response.data.hasOwnProperty("duplicateEmails")) {
                    this.duplicateEmails = response.data.duplicateEmails;
                    this.inProcess = false;
                    return;
                } else {
                    await this.$store.dispatch("updatePreRequisite", 'creditVendor');
                    this.$snotify.success("Lenders added successfully.");
                    this.inProcess = false;
                }
            } else {
                await this.$store.dispatch("updatePreRequisiteOnServer", {
                    userType: this.$userType,
                    userId: this.$userId,
                    brokerId: this.$brokerId,
                    num: 'creditVendor'
                });
                await this.$store.dispatch("updatePreRequisite", 'creditVendor');
                this.inProcess = false;
            }
        } catch (error) {
            console.log("error");
        }
    }

  mounted() {
    // this.addLenders();
    // this.getLenders();
    this.getAppovedLenderList();
    if (this.$loader) {
      this.$loader.hide();
    }
  }

  beforeDestroy() {
    if (this.$route.name == "Information") {
      this.$snotify.clear();
      this.$snotify.info("Sign-Up is in process. You cannot go back.", {
        timeout: 999999
      });
      this.$router.push({ name: "LenderInfo" });
    }
  }




  //To get the Approved lender data
  public async getAppovedLenderList() {
    try {
      this.lenderLoader = true;
      let filteredLender = null;
      let isLenderAdded = false;
      let queryParamLender = this.$route.query.selectedLender;
      let body = {
        userId: this.$userId,
        brokerId: this.$brokerId,
        userType: this.$userType,
        fieldName: this.searchByType,
        type: this.defaultLabelName,

        searchString:
          this.searchString != "" && this.searchString != null
            ? this.searchString
            : null,
      };
      let response = await Axios.post(
        BASE_API_URL + "onBoarding/getAppovedLenderList",
        body);
      this.lenderList = response.data.lender;
      this.addedLenders = response.data.addedData;
      this.lenderList.push({'lenderName': 'Others','_id':'others'});
      isLenderAdded = this.addedLenders.find(lender => { return lender.lenderName == queryParamLender})
      if(queryParamLender && !isLenderAdded){
        filteredLender = this.lenderList.find(lender => { return lender.lenderName == queryParamLender})
        if(filteredLender) this.getData(filteredLender);
        this.$modal.show("addLender");
      }
    } catch (error) {
      console.log(error.response);
    } finally {
      this.lenderLoader = false;
    }
  }

  public async getData(lender) {
    this.lenderInfo.lenderNameAndId = {
      _id: lender._id,
      lenderName: lender.lenderName
    };

    if (this.lenderInfo.lenderNameAndId._id) {
      this.selectLender = false;
    }
  }

  public hideModel() {
    this.$modal.hide("addLender");
    this.defaultLabelName = "Add Lender";
    this.defaultButtonName = "Add";
    this.lenderInfo = {
      lenderNameAndId: {}
    };
    this.selectLender = false;
    this.phoneNumber = {
      first: null,
      middle: null,
      last: null
    };
    this.phoneError = false;
  }
  public moveToSecond() {
   this.phoneError=false
    if (this.phoneNumber.first.length == 3) {
      document.getElementById("second").focus();
    }
  }
  public async saveLenderInfo() {
    try {
      this.inProcess = true;
      let formValid = await this.$validator.validateAll();
      let isRequired = this.checkValidation(formValid);
      if (isRequired) return;
      let lenderName = null;
      let lenderId = null;
      if (
        this.lenderInfo.lenderNameAndId == "Others" ||
        this.lenderInfo.lenderNameAndId.lenderName == "Others"
      ) {
        lenderName = this.lenderInfo.lenderName;
        lenderId = this.lenderInfo.lenderNameAndId._id
          ? this.lenderInfo.lenderNameAndId._id
          : null;
      } else {
        lenderName = this.lenderInfo.lenderNameAndId.lenderName;
        lenderId = this.lenderInfo.lenderNameAndId._id;
      }
      this.phoneError = false;
      this.lenderInfo.phone = `(${this.phoneNumber.first}) ${this.phoneNumber.middle}-${this.phoneNumber.last}`;
      let lenderData: any = {
        lenderName: lenderName,
        lenderId: lenderId,
        brokerId: this.$brokerId,
        userType: this.$userType,
        lenderInfo: this.lenderInfo,
        type: this.defaultButtonName,
        userId: this.$userId
      };

      let response = await Axios.post(
        BASE_API_URL + "onBoarding/saveLenderInfo",
        { lenderData });
      if (response.data.statusCode == 500) {
        this.inProcess = false;
        this.hasErrorMessage = true;
        this.errorMessage = response.data.message;
      } else {
        this.getAppovedLenderList();
        this.$snotify.success("Lender added successfully!");
        this.inProcess = false;
        this.hideModel();
      }
    } catch (error) {
      console.log(error);
    }
  }
  public checkValidation(formValid) {
    let isRequired = false;
    if (!formValid) {
      this.inProcess = false;
      isRequired = true;
    }
    if (
      this.phoneNumber.first == null ||
      this.phoneNumber.first == "" ||
      this.phoneNumber.middle == null ||
      this.phoneNumber.middle == "" ||
      this.phoneNumber.last == null ||
      this.phoneNumber.last == ""
    ) {
      this.phoneError = true;
      this.inProcess = false;
      isRequired = true;
    }
    return isRequired;
  }
  public moveToThird() {
    this.phoneError=false
    if (this.phoneNumber.middle.length == 3) {
      document.getElementById("third").focus();
    }
  }
  public allowEditVendors(lender) {
    if (this.userType == "Broker") {
      if (
        lender.addedBy == this.$userId ||
        lender.addedByUserType == "Super Admin"
      ) {
        return true;
      } else {
        return false;
      }
    } else if (this.userType == "Admin Staff") {
      if (lender.addedBy == this.$userId || lender.brokerVendorReferenceId) {
        return true;
      }
    } else {
      return true;
    }
  }
  public async editLenderInfo(lender) {
    let phone = lender.phone.split("");
    this.phoneNumber.first = phone[1] + phone[2] + phone[3];
    this.phoneNumber.middle = phone[6] + phone[7] + phone[8];
    this.phoneNumber.last = phone[10] + phone[11] + phone[12] + phone[13];
    this.editLenderData = Object.assign({}, lender);
    this.defaultLabelName = "Edit Lender";
    this.defaultButtonName = "Update";
    this.lenderInfo = this.editLenderData;
    this.getAppovedLenderList();
    this.$modal.show("addLender");
  }
  
  public async opneConfirmPopup(id) {
    try {
      this.$modal.show("confirmDeleteFiles");
      this.lenderId = id;
    } catch (error) {
      console.log(error);
    }
  }
    // Getting lender data on previous button click

    //To get the lender data on click of previous button
    public async getLenders() {
        try {
            let response = await Axios.get(
                BASE_API_URL +
                "broker/getLender/" +
                this.$userId +
                "/" +
                this.userType +
                "/" +
                this.$brokerId);
            if (response.data.length > 0) {
                if (this.userType == "MLO") {
                    response.data = JSON.parse(JSON.stringify(response.data));
                    let modifiedBrokerLenders = [];
                    response.data.forEach(e => {
                        if (e.brokerlenderReferenceId) {
                            modifiedBrokerLenders.push(e.brokerlenderReferenceId);
                        }
                    });
                    let mloLenders = [];
                    response.data.forEach(element => {
                        if (
                            element.addedBy == this.$brokerId ||
                            element.addedByUserType == "Super Admin"
                        ) {
                            if (!modifiedBrokerLenders.includes(element._id)) {
                                element.brokerlenderReferenceId = element._id;
                                delete element._id;
                                element.userName = null;
                                element.password = null;
                                mloLenders.push(element);
                            }
                        } else {
                            mloLenders.push(element);
                        }
                    });
                    this.brokerLenders = mloLenders;
                } else {
                    this.brokerLenders = response.data;
                }
            }
        } catch (error) {
            console.log(error.response);
        }
    }

  public async del(yes) {
    try {
      let body = {
        lenderId: this.lenderId,
        brokerId: this.$brokerId
      };
      if (this.delete == yes) {
        let response = await Axios.post(
          BASE_API_URL + "onBoarding/deleteLender",
          body);
        this.getAppovedLenderList();
        this.$snotify.success("Lender deleted successfully");
        this.$modal.hide("confirmDeleteFiles");
      } else {
        this.$modal.hide("confirmDeleteFiles");
      }
    } catch (error) {}
  }

  getBrandingInformation(){
    return this.$store.state.brandingInformation || {};
  }

}
