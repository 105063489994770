





































import {
    Component,
    Vue
} from "vue-property-decorator";
import BrokerInformation from "@/components/broker/pre-requisites/broker-information/BrokerInformation.vue";
import LenderInformation from "@/components/broker/pre-requisites/lender-information/LenderInformation.vue";
import VendorInformation from "@/components/broker/pre-requisites/vendor-information/VendorInformation.vue";
import Contract from "@/components/broker/pre-requisites/contract/Contract.vue";
import BrokerStaffs from "@/components/broker/pre-requisites/broker-staffs/BrokerStaffs.vue";
import AccessRights from "@/components/broker/pre-requisites/access-rights/AccessRights.vue";
import OnBoardingCall from "@/components/broker/pre-requisites/onboarding-call/OnboardingCall.vue";
import Disclosures from "@/components/broker/pre-requisites/disclosures/Disclosures.vue";
import Compliance from "@/components/broker/pre-requisites/compliance/Compliance.vue";

// import ManageProcessor from "@/components/broker/pre-requisites/manage-processor/ManageProcessor.vue"
import vueCustomScrollbar from "vue-custom-scrollbar";
import {
    ON_BOARDING_STEPS
} from "@/constants"


@Component({
    components: {
        BrokerInformation,
        LenderInformation,
        VendorInformation,
        Contract,
        BrokerStaffs,
        AccessRights,
        Disclosures,
        OnBoardingCall,
        vueCustomScrollbar,
        Compliance
        // ManageProcessor
    }
})
export default class PrerequisitesComponent extends Vue {
    public onboardingSteps: any = ON_BOARDING_STEPS;

    get lastPage() {
        let user = this.$store.state.sessionObject.userInfo;

        if (user?.completedPreRequisites) {
            return (this.$userType === 'MLO' && user.completedPreRequisites === 'welcome')
                ? 'lenderInfo'
                : user.completedPreRequisites;
        }

        if (this.$userType === 'MLO') { return 'lenderInfo' };
        return 'welcome';
    }

    public isStepComplete(step) {
        let currentStepIdx = this.onboardingSteps.findIndex(stp => {
            return stp.stepId == step.stepId;
        })
        let activeStepIdx = this.onboardingSteps.findIndex(stp => {
            return this.lastPage == stp.stepId;
        })
        return currentStepIdx <= activeStepIdx;
    }

    beforeMount() {
        if (!Vue.prototype.$userType)
            Vue.prototype.$userType = this.$store.state.sessionObject.type;
    }

    async mounted() {
        this.onboardingSteps= this.onboardingSteps.filter(a => !a.hidden);
    }
}
