export class wemlouserdetail {
    public userId : string;
    public firstName : string;
    public lastName : string;
    public phone: number;
    public email : string;
    public userType : string;
    public reportees: Array<string>;
    public addedBy: string;
    public position: string;
    public reportTo:any ;
    public processorRelationship:string;
    public NMLS : string;
    public addedByUserType : string;
    public isManager: boolean;
    public managerId: string;
    public managerMustQCNewLoansAtLoanSetup: boolean;
    public assistantId: string;
}