


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import { PROPERTY_TYPE } from "@/constants";
import { BrokerInformation } from "@/models/broker.model";

import { HollowDotsSpinner } from "epic-spinners";

@Component({ components: { HollowDotsSpinner } })
export default class BrokerInformationComponent extends Vue {
  public brokerInformation: any = new BrokerInformation();
  public user: any = {};
  public inProcess: boolean = false;
  public phoneDigitErrorFlag = false;
  public phoneFormatErrorFlag = false;
  public stateCountyLoader = false;
  public fetchState = [];
  public propertyType = PROPERTY_TYPE;

  public async validateAndSubmit() {
    try {
      this.inProcess = true;

      let formValid = await this.$validator.validateAll();
      if (
        formValid &&
        !this.phoneDigitErrorFlag &&
        !this.phoneFormatErrorFlag
      ) {
        this.brokerInformation.firstName =
          this.brokerInformation.firstName.charAt(0).toUpperCase() +
          this.brokerInformation.firstName.substring(1).toLowerCase();
        this.brokerInformation.lastName =
          this.brokerInformation.lastName.charAt(0).toUpperCase() +
          this.brokerInformation.lastName.substring(1).toLowerCase();
        this.brokerInformation.email = this.brokerInformation.email.toLowerCase();
        this.brokerInformation.token = this.activationToken;
        this.brokerInformation.userId = this.userId;
        let response = await Axios.post(
          BASE_API_URL + "broker/saveBrokerInformation",
          this.brokerInformation);
        this.$snotify.success("Information saved successfully!");
        this.$store.dispatch("updateSessionData", response.data.userInfo);
        this.$store.dispatch("updateSessionStates", response.data.states);
        this.$store.dispatch("removeStatusOfVerfiedToken");
        this.inProcess = false;
      } else {
        this.inProcess = false;
      }
    } catch (error) {
      this.$snotify.error(error);
    }
  }

  get userId() {
    return this.$store.state.sessionObject.userId;
  }

  get activationToken() {
    return this.$store.state.sessionObject.activationToken;
  }
  get session() {
    return this.$store.state.sessionObject;
  }

  public get stateList() {
    return this.$store.state.sessionObject.states;
  }

  public async getCityAndState() {
    try {
      this.stateCountyLoader = true;
      this.fetchState = [];
      let zip = this.brokerInformation.address.zip;
      let response = await Axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json?sensor=true&key=AIzaSyC4z_vM_4vNFs5_KH5wUdTjJrP8yLS9lr8&components=country:US|postal_code:" +
          zip,
          { headers: { public: true}}
      );
      if (response.status == 200 && response.data.status == "OK") {
        response.data.results[0]["address_components"].forEach(element => {
          if (element.types.includes("locality")) {
            this.brokerInformation.address.city.name = element.long_name;
          } else if (
            !this.brokerInformation.address.city.name &&
            element.types.includes("administrative_area_level_3")
          ) {
            this.brokerInformation.address.city.name = element.long_name;
          }

          if (element.types.includes("administrative_area_level_1")) {
            this.brokerInformation.address.state.name = element.long_name;
            this.brokerInformation.address.state.code = element.short_name;
            this.fetchState.push({
              name: element.long_name,
              code: element.short_name
            });
          }
        });
      } else if (response.data.status == "ZERO_RESULTS") {
        this.stateList.forEach(state => {
          this.fetchState.push({ name: state.state, code: state.code });
        });
      }
      this.stateCountyLoader = false;
    } catch (error) {
      this.stateCountyLoader = false;
      console.log(error.response);
    }
  }

  public checkIfStateHasCode() {
    let state = this.brokerInformation.address.state.name;
    this.stateList.forEach(f => {
      if (f.state == state) {
        this.brokerInformation.address.state.code = f.code;
      }
    });
  }

  public checkPhoneLength() {
    let phone: any = this.brokerInformation.phone;
    var numbers = /^[0-9]+$/;
    if (phone.length == 10) {
      this.phoneDigitErrorFlag = false;
      if (phone.match(numbers)) {
        this.phoneFormatErrorFlag = false;
      } else {
        this.phoneFormatErrorFlag = true;
      }
    } else if (phone.length > 0 && phone.length != 10) {
      this.phoneDigitErrorFlag = true;
      if (phone.match(numbers)) {
        this.phoneFormatErrorFlag = false;
      } else {
        this.phoneFormatErrorFlag = true;
      }
    }
  }

  beforeMount() {
    /**
     * Set the broker information if it exist in state
     */
    if (this.session.userInfo ) {
      this.brokerInformation.firstName = this.session.userInfo.firstName;
      this.brokerInformation.lastName = this.session.userInfo.lastName;
      this.brokerInformation.email = this.session.userInfo.email;

    }
    this.brokerInformation.email = this.session.email;
  }

  mounted() {
    Vue.prototype.$brokerId = this.userId;
    Vue.prototype.$userId = this.userId;
    if (!Vue.prototype.$userType)
      Vue.prototype.$userType = this.$store.state.sessionObject.type;
    if (this.$loader) {
      this.$loader.hide();
    }
  }
}
