import { render, staticRenderFns } from "./Compliance.html?vue&type=template&id=a2c4306e&"
import script from "./Compliance.vue?vue&type=script&lang=ts&"
export * from "./Compliance.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports