


import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
import ComplianceList from "@/views/compliance/ComplianceList.vue";

import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import draggable from "vuedraggable";

@Component({
  components: {
    PreRequisiteHeader,
    draggable,
    ComplianceList
  },
})
export default class ComplianceComponent extends mixins(CommonMixin) {
  public userId = this.$brokerId;
  public previousInProcess: boolean = false;
  public inProcess: boolean = false;
  public complianceList: any[] = [];

  public async proceedNext() {
    this.inProcess = true;
    await this.$store.dispatch("updatePreRequisiteOnServer", {
      userType: this.$userType,
      userId: this.$brokerId,
      num: "schedule",
      changeInState: true,
    });
    this.inProcess = false;
  }

  //Added Processing on click of previous button
  public async goPrevious() {
    this.previousInProcess = true;
    await this.modifyPreRequisite("disclosures");
    this.previousInProcess = false;
  }

  public async submit(){
    let selectedComplianceIds = this.$refs.complianceList['selectedComplianceIds'];
    let complianceList = this.$refs.complianceList['complianceList'];
    complianceList.forEach(c => {
      c.selected = selectedComplianceIds.includes(c.categoryName);
    })
    this.$store.state.wemloLoader = true;
    complianceList = complianceList.map(c => ({categoryName: c.categoryName, selected : c.selected}))
     let response = await Axios.post(BASE_API_URL + "onBoarding/saveBrokerDefaultCompliance",
      {
        complianceList: complianceList
      });
    this.$snotify.success(response.data.message);
    if(response.data.success){
      this.proceedNext();
    }
    this.$store.state.wemloLoader = false;
  }

  public async getBrokerDefaultCompliance() {
    try {
      this.$store.state.wemloLoader = true;
      
      const {data = []} = await Axios.post(BASE_API_URL + "onBoarding/getBrokerDefaultCompliance", {});

      this.complianceList = data;
    } catch(error) {
      console.log(error);
      this.$snotify.error("Failed to get Compliance Package");
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  async mounted() {
    await this.getBrokerDefaultCompliance();
  }
}
