


import {
    Component,
    Vue
} from "vue-property-decorator";
import Axios from "axios";
import {
    BASE_API_URL
} from "@/config";
import {
    ACCESS_RIGHTS_COMPONENTS
} from "../../../../constants";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
import {
    mixins
} from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";

import { getNextOnboardingStepId, isPriorStepsComplete} from "@/constants";

@Component({
    components: {
        PreRequisiteHeader
    }
})
export default class listUserAccessComponent extends mixins(CommonMixin) {
    public users: any = [];
    public previousInProcess: boolean = false;
    public inProcess = false;
    public initialPagination = {
        start: 0,
        end: 15,
    };
    public pagination: any = JSON.parse(JSON.stringify(this.initialPagination));
    public skip = 0;
    public searchByType = null;
    public searchLoanString = "";
    public userLoader: boolean = true;
    public loadMoreScroll: boolean = true;
    public currentLengthOnScroll = 0;
    public totalStaff = 0;

  public async showMoreDataOnScroll() {
    if (!this.loadMoreScroll && this.currentLengthOnScroll < this.totalStaff) {
      this.loadMoreScroll = true;
      await this.getListForAccessRights(false);
    }
  }

    public async getListForAccessRights(mountCall: boolean = false) {
        try {
            const limit = 10;
            this.skip = mountCall ? 0 : this.skip + limit;
            if (mountCall) {
              this.userLoader = true;
            }
            let response = await Axios.post(
                BASE_API_URL + "broker/listUsersForAccess/", {
                    brokerId: this.$brokerId,
                    userType: this.$userType,
                    fieldName: this.searchByType,
                    options: {
                        skip: this.skip,
                        limit,
                    },
                    searchString: this.searchLoanString != "" && this.searchLoanString != null ?
                        this.searchLoanString : null
                });

            response.data.staffsData = JSON.parse(
                JSON.stringify(response.data.staffsData)
            );
          this.totalStaff = response.data.totalStaff;
            let users = response.data.staffsData;
            users.forEach(element => {
                if (element.user.userAccess.length == 0) {
                    element.user.userAccess = ACCESS_RIGHTS_COMPONENTS;
                }
            });
            users = JSON.parse(JSON.stringify(users));
            this.users = mountCall ? users : this.users.concat(users);
            this.currentLengthOnScroll = this.users.length;
        } catch (error) {
            if (error.response.status == 409) {
                console.log("error");
            }
        } finally {
          this.loadMoreScroll = false;
          this.userLoader = false;
        }
    }

    mounted() {
        this.getListForAccessRights(true);
        if (this.$loader) {
            this.$loader.hide();
        }
    }

    checkmarkStyle(type: any) {
        switch (type) {
          case "MLO":
            return "blue_user";
          case "Admin Staff":
            return "checkmark1";
          default:
            return "checkmark2";
        }
    }

    getComponentIndex(index: any, name: any) {
        return this.users[index].user.userAccess.findIndex(
            (access: any) => access.component == name
        );
    }

    public async submitDataForAccessRights() {
        try {
            this.inProcess = true;
            let response = await Axios.post(
                BASE_API_URL + "broker/addAccessRights", {
                    users: this.users,
                    brokerId: this.brokerId
                });
            if (response.status == 200) {
                let nextPrerequisite = getNextOnboardingStepId(this.$store, 2);
                await this.$store.dispatch("updatePreRequisite", nextPrerequisite);
            }
            this.inProcess = false;
        } catch (error) {
            this.$snotify.error(error.response.message);
            this.inProcess = false;
        }
    }
    public setPopOver(user, id) {
        if (user.userType == "Admin Staff") {
            let ids: any = document.getElementById(id);
            ids.checked = false;
            ids.disabled = true;
        }
    }
    public async goPrevious() {
        this.previousInProcess = true;
        await this.modifyPreRequisite('team');
        this.previousInProcess = false;
    }
}
