export class CreditVendorModel {
    constructor() {
        this.vendorType = 'Credit Vendor';
        this.message = 'Let’s make sure we are integrated with your credit reporting vendor.'
        this.vendorName = '';
        this.vendorWebsite = '';
        this.userName = '';
        this.password = '';
        this.brokerVendorReferenceId = null;
        //this.brokerId = '';
    }
    public message: string;
    public vendorType: string;
    public vendorName: string;
    public vendorWebsite: string;
    public userName: string;
    public password: string;
    public brokerVendorReferenceId: string;
    //public brokerId: string;
}