


import {
    Component,
    Vue
} from "vue-property-decorator";
import Axios from "axios";
import {
    BASE_API_URL
} from "@/config";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
import {
    mixins
} from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import {
    isStepsNotComplete
} from "@/constants";
import _ from "lodash";

@Component({
    components: {
        PreRequisiteHeader
    }
})
export default class ContractComponent extends mixins(CommonMixin) {
    public agreed = "I Agree";
    public agreementData: any = {
        year: new Date().getFullYear(),
        month: "",
        day: new Date().getDate(),
        signDate: (new Date()).toLocaleDateString('en-US'),
        brokerName: this.nameOfBroker,
        sign: false,
        address: ''
    };
    public userId = this.$brokerId;
    public brokerEmails = this.brokerEmail;
    public isAgreementSigned = false;
    public previousInProcess: boolean = false;
    public inProcess: boolean = false;
    public agreementPath = null;
    public companyName = null;
    public username = null;
    public isMottoMortgage = null;
    public state = '';

    get nameOfBroker() {
        return (
            this.$store.state.sessionObject.userInfo.firstName +
            " " +
            this.$store.state.sessionObject.userInfo.lastName
        );
    }

    get brokerEmail() {
        return this.$store.state.sessionObject.userInfo.email;
    }

    get isDisabled() {
        return !this.agreementData.sign;
    }

    get email() {
        return this.$store.state.sessionObject.email;
    }

    get completedPreRequisite() {
        return this.$store.state.sessionObject.userInfo.completedPreRequisites;
    }

    public stepsNotCompleted(stepId) {
        return isStepsNotComplete(this.completedPreRequisite, stepId)
    }

    public async validateAndSubmit() {
        try {
            if (this.isAgreementSigned) return;

            this.agreementData["email"] = this.brokerEmail;
            this.agreementData.companyName = this.companyName;
            this.agreementData.username = this.username;
            this.agreementData.isMottoMortgage = this.isMottoMortgage;

            let response = await Axios.post(
                BASE_API_URL + "broker/brokerContract", {
                    data: this.agreementData,
                    brokerId: this.userId,
                    agreementData: this.agreementData
                });
            this.getContractData();
        } catch (error) {
            console.log(error.response);
        }
    }

    public async proceedNext(agreed = true) {
        this.inProcess = true;
        await this.$store.dispatch("updatePreRequisiteOnServer", {
            userType: this.$userType,
            userId: this.$brokerId,
            num: 'team',
            changeInState: true
        });
        this.inProcess = false;
    }

    public async checked(event) {
        let check = event.target.value;
        if (check == "true") {
            this.validateAndSubmit();
        }
    }

    public async sendTermsAndConditionsOverMail() {
        try {
            this.agreementData["email"] = this.brokerEmail;
            Axios.post(
                BASE_API_URL + "broker/mailTermsAndConditions", {
                    agreementData: this.agreementData,
                    brokerId: this.$brokerId
                });
            this.$snotify.success("Email has been sent successfully.");
        } catch (error) {
            console.log(error.response);
        }
    }

    public async weekdays() {
        let d = new Date();
        let month = [
            "January",
            "Febuary",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        this.agreementData.month = month[d.getMonth()];
    }

    get getBrokerType() {
        return this.$store.state.sessionObject.type == "Broker";
    }

    public printTermsAndConditions() {
        if (this.agreementPath) {
            let agreementPath = this.agreementPath;
            this.printImage(agreementPath);
        }
    }

    public async getContractData() {
        try {
            let response: any = await Axios.post(
                BASE_API_URL + "broker/getContractData", {
                    brokerId: this.$brokerId
                });
            if (response.data) {
                if (
                    response.data.hasOwnProperty("agreement") &&
                    response.data.agreement.sign
                ) {
                    this.isAgreementSigned = true;
                    this.agreementData.sign = true;
                }
                this.agreementData.address = response.data.address;
                this.agreementPath = response.data.agreementPath;
                this.companyName = response.data.companyName;
                this.username = `${_.get(response.data, 'firstName', '')} ${_.get(response.data, 'middleName', '')} ${_.get(response, 'data.lastName', '')}, ${_.get(response.data, 'nameSuffix', '')}.`;
                this.isMottoMortgage = response.data.isMottoMortgage;
                this.state = _.get(this.agreementData, 'address.state.name');   
            }
        } catch (error) {
            console.log(error.response);
        }
    }

    //Added Processing on click of previous button
    public async goPrevious() {
        this.previousInProcess = true;
        await this.modifyPreRequisite('aus');
        this.previousInProcess = false;
    }

    async mounted() {
        this.weekdays();
        await this.getContractData();

        if (this.$loader) {
            this.$loader.hide();
        }
        if (this.agreementData.day == 1 || this.agreementData.day == 21 || this.agreementData.day == 31) {
            this.agreementData.day = this.agreementData.day + " " + "st";
        } else if (this.agreementData.day == 2 || this.agreementData.day == 22) {
            this.agreementData.day = this.agreementData.day + " " + "nd";
        } else if (this.agreementData.day == 3 || this.agreementData.day == 23) {
            this.agreementData.day = this.agreementData.day + " " + "rd";
        } else {
            this.agreementData.day = this.agreementData.day + " " + "th";
        }
    }
}
