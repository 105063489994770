export class AUSVendorModel {
    constructor() {
        this.vendorType = 'AUS Vendor';
        this.message = 'The information below will be used for Wemlo to run AUS on your behalf.';
        this.fannieMae = new Credentials();
        this.freddieMac = new Credentials();

        //this.brokerId = '';
    }
    public message: string;
    public vendorType: string;
    public fannieMae: Credentials;
    public freddieMac: Credentials;

    //public brokerId: string;
}

class Credentials {
    constructor() {
        this.userName = '';
        this.password = '';
        this.brokerVendorReferenceId = null;
    }
    public userName: string;
    public password: string;
    public brokerVendorReferenceId: string;
}
