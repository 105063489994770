export class AMCVendorModel {
    constructor() {
        this.vendorType = 'Appraisal';
        this.message = 'The information below will be used for wemlo to order appraisals on your behalf.'
        this.vendorName = '';
        this.vendorWebsite = '';
        this.userName = '';
        this.password = '';
        this.multiEntry = true;
        this.brokerVendorReferenceId = null;
    }
    public message : string;
    public vendorType : string;
    public vendorName : string;
    public vendorWebsite : string;
    public userName: string;
    public password: string;
    public multiEntry: boolean;
    public brokerVendorReferenceId: string;
}