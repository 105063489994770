


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, setInstanceVariables } from "@/config";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";

import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";

import { getNextOnboardingStepId, isPriorStepsComplete } from "@/constants";

@Component({
  components: {
    PreRequisiteHeader,
  },
})
export default class onBoardingCallComponent extends mixins(CommonMixin) {
  public inProcess = false;
  public previousInProcess: boolean = false;
  public calendlyScript = document.createElement("script");

  public async nextStep() {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/onBoardingCall",
        {
          brokerId: this.$brokerId,
        }
      );
      let nextPrerequisite = getNextOnboardingStepId(this.$store, 2);
    //   await this.$store.dispatch("updatePreRequisite", nextPrerequisite);
      await this.$store.dispatch("updatePreRequisiteOnServer", {
        userType: this.$userType,
        userId: this.$brokerId,
        num: nextPrerequisite,
        changeInState: true,
      });
      await setInstanceVariables();
      await this.$store.dispatch("setSidenavLinks", {
        type: "Broker",
        access: [],
      });
      this.$router.push("/broker/dashboard");
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }
  public async goPrevious() {
    this.previousInProcess = true;
    let nextPrerequisite = getNextOnboardingStepId(this.$store, 1);
    await this.modifyPreRequisite(nextPrerequisite);
    this.previousInProcess = false;
  }

  mounted() {
    this.calendlyScript.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    document.head.appendChild(this.calendlyScript);
    if (this.$loader) {
      this.$loader.hide();
    }
  }
}
