export class MLO {
    public userId: string;
    public brokerId: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: number;
    public NMLSNumber: string;
    public CommissionStructure: string;
    public userActive: boolean;
    public stateLicenseId: string;
    public eni: string;
    public createdOn: string;
}