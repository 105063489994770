





































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import { getNextOnboardingStepId, isPriorStepsComplete } from "@/constants";
// Importing child components
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import _ from "lodash";
import PDFViewer from "@/components/pdf/PDFViewer.vue";
import DisclosuresList from "@/views/disclosures/DisclosuresList.vue"


@Component({ components: { PDFViewer, DisclosuresList } })
export default class ChooseDisclosures extends mixins(CommonMixin) {
  inProcess = false;
  previousInProcess: boolean = false;
  userId: String = null;
  loanTxnId: String = null;
  disclosures = [];
  disclosuresFHA = [];
  disclosuresVA = [];
  disclosuresConventional = [];

  async submit() {
    try {
      return await this.submitDisclosureData();
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async goPrevious() {
    this.previousInProcess = true;
    let nextPrerequisite = getNextOnboardingStepId(this.$store, 1);
    await this.modifyPreRequisite(nextPrerequisite);
    this.previousInProcess = false;
  }

  async submitDisclosureData() {
    try {
      this.$store.state.wemloLoader = true;

      const payload = {
        disclosures: [
          ...this.disclosuresFHA
            .filter(disclosure => disclosure.documentStatus === 'Wesign Required')
            .map(disclosure => ({ formId: disclosure.formId, loanType: 'FHA' })),

          ...this.disclosuresVA
            .filter(disclosure => disclosure.documentStatus === 'Wesign Required')
            .map(disclosure => ({ formId: disclosure.formId, loanType: 'VA' })),

          ...this.disclosuresConventional
            .filter(disclosure => disclosure.documentStatus === 'Wesign Required')
            .map(disclosure => ({ formId: disclosure.formId, loanType: 'Conventional' })),
        ]
      };

      let response = await Axios.post(
        BASE_API_URL + "broker/saveDefaultDisclosures",
        payload);

      this.$snotify.success(response.data.message);
      this.$store.state.wemloLoader = false;
      let nextPrerequisite = getNextOnboardingStepId(this.$store, 2);

      await this.$store.dispatch("updatePreRequisite", nextPrerequisite);

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  onDisclosuresListChangeFHA(disclosuresList) { this.disclosuresFHA = [ ...disclosuresList ]; }
  onDisclosuresListChangeVA(disclosuresList) { this.disclosuresVA = [ ...disclosuresList ]; }
  onDisclosuresListChangeConventional(disclosuresList) { this.disclosuresConventional = [ ...disclosuresList ]; }

  get orderedDisclosures() {
    const list =
      [ ...this.disclosuresFHA, ...this.disclosuresVA , ...this.disclosuresConventional ]
        .filter(disclosure => disclosure.documentStatus === 'Wesign Required');

    return _.orderBy(_.uniqBy(list, 'formId'), ['order', 'formId']);
  }

  isDefault(disclosure, wemloDefaultDisclosures, loanType) {
    const defaultDisclosure =
      wemloDefaultDisclosures
        .filter(wemloDefault => wemloDefault.loanType === loanType)
        .find(wemloDefault => wemloDefault.formId === disclosure.formId);

    disclosure.documentStatus =
      !!defaultDisclosure
        ? 'Wesign Required'
        : 'Wesign Not Required';

    disclosure.locked = _.get(defaultDisclosure, 'locked', false);
  }

  filterDisclosuresByLoan(forms, brokerName) {

    const rule = (broker, brokerName) => {
        if (broker.slice(-1) === '*') {
            return _.startsWith(brokerName, broker.replace(/\*/g, ''));
        }

        if (broker[0] === '*') {
            return _.endsWith(brokerName, broker.replace(/\*/g, ''));
        }

        return broker === brokerName;
    };

    const brokerSpecificForms = forms.filter(form => _.get(form, 'brokers', []).some(broker => rule(broker, brokerName)));

    return brokerSpecificForms.length
        ? brokerSpecificForms
        : forms.filter(form => _.get(form, 'brokers', []).length === 0);
  }

  async fetchDefaultDisclosure() {
    try {
      const { data: { disclosures } } = await Axios.post(
        BASE_API_URL + "disclosure/getDisclosuresConstants");

      const { data: { wemloDefaultDisclosures } } = await Axios.get(
        BASE_API_URL + "disclosure/getWemloDefaultDisclosures");

      const { data: { brokerDetail: { companyName } } } = await Axios.get(BASE_API_URL + "broker/getBrokerDetails", {
        params: { brokerId: this.$brokerId }
      });

      this.disclosures = this.filterDisclosuresByLoan(disclosures, companyName);

      this.disclosuresFHA = _.cloneDeep(this.disclosures.filter(disclosure => disclosure.loanTypes.includes('FHA')));
      this.disclosuresVA = _.cloneDeep(this.disclosures.filter(disclosure => disclosure.loanTypes.includes('VA')));
      this.disclosuresConventional = _.cloneDeep(this.disclosures.filter(disclosure => disclosure.loanTypes.includes('Conventional')));

      this.disclosuresFHA.forEach(disclosure => this.isDefault(disclosure, wemloDefaultDisclosures, 'FHA'));
      this.disclosuresVA.forEach(disclosure => this.isDefault(disclosure, wemloDefaultDisclosures, 'VA'));
      this.disclosuresConventional.forEach(disclosure => this.isDefault(disclosure, wemloDefaultDisclosures, 'Conventional'));
    } catch (error) {
      console.error(error);
    }
  }

  async mounted() {
    this.userId = String(this.$userId)
    this.loanTxnId = String(this.$route.query.id);
    await this.fetchDefaultDisclosure();
  }
}
