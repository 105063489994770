


import {
    Component,
    Vue
} from "vue-property-decorator";
import Axios from "axios";
import {
    MLO
} from "../../../../models/mlo.model";
import {
    BASE_API_URL
} from "../../../../config";
import {
    AdminStaff
} from "../../../../models/admin-staff.model";
import {
    wemlouserdetail
} from "@/models/superadmin/superadmin.model";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
import {
    mixins
} from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import {
    LogIn
} from "../../../../models/login.model";
@Component({
    components: {
        PreRequisiteHeader
    }
})
export default class AdminMloComponent extends mixins(CommonMixin) {
    public mlos = new Array < MLO > ();
    public adminStaffs = new Array < AdminStaff > ();
    public brokerProcessor = new Array < wemlouserdetail > ();
    public emailTaken: boolean = false;
    public formValid: boolean = false;
    public validateMessage: string = "";
    public duplicateEmails: string[] = [];
    public repeatedEmails: string[] = [];
    public inProcess: boolean = false;
    public previousInProcess: boolean = false;
    public emptyFirstName = [];
    public emptyLastName = [];
    public emptyEmails = [];
    public notSelectRealtions = [];
    public activateWemPro = true;
    public mlosToDelete = [];
    public adminStaffsToDelete = [];
    public brokerProcessorToDelete = [];
    public arrayOfUnrequired = [
        "_id",
        "createdOn",
        "stateLicenseId",
        "brokerId",
        "userId",
        "__v",
        "token",
        "notificationSetting",
        "wemloMember",
        "inLoadBalancing",
        "addedBy",
        "userType"
    ];
    //submitting the broker staff data to serve
    public async validateAndSubmit() {
        let existingEmailOrPhone = false;
        this.mlos.forEach(mlo => {
            if (mlo.firstName) {
                mlo.firstName =
                    mlo.firstName.charAt(0).toUpperCase() +
                    mlo.firstName.substring(1).toLowerCase();
            }
            if (mlo.lastName) {
                mlo.lastName =
                    mlo.lastName.charAt(0).toUpperCase() +
                    mlo.lastName.substring(1).toLowerCase();
            }
            mlo.email = mlo.email ? mlo.email.toLowerCase() : null;
            if (mlo.email && this.duplicateEmails.includes(mlo.email))
                existingEmailOrPhone = true;
        });
        this.brokerProcessor.forEach(bp => {
            if (bp.firstName) {
                bp.firstName =
                    bp.firstName.charAt(0).toUpperCase() +
                    bp.firstName.substring(1).toLowerCase();
            }
            if (bp.lastName) {
                bp.lastName =
                    bp.lastName.charAt(0).toUpperCase() +
                    bp.lastName.substring(1).toLowerCase();
            }
            bp.email = bp.email ? bp.email.toLowerCase() : null;
            if (bp.email && this.duplicateEmails.includes(bp.email))
                existingEmailOrPhone = true;
        });
        this.adminStaffs.forEach(as => {
            if (as.firstName) {
                as.firstName =
                    as.firstName.charAt(0).toUpperCase() +
                    as.firstName.substring(1).toLowerCase();
            }
            if (as.lastName) {
                as.lastName =
                    as.lastName.charAt(0).toUpperCase() +
                    as.lastName.substring(1).toLowerCase();
            }
            as.email = as.email ? as.email.toLowerCase() : null;
            if (as.email && this.duplicateEmails.includes(as.email))
                existingEmailOrPhone = true;
        });
        this.inProcess = true;
        let formValid = await this.$validator.validateAll();
        if (
            formValid &&
            this.emptyLastName.length == 0 &&
            this.notSelectRealtions.length == 0 &&
            this.emptyFirstName.length == 0 &&
            this.emptyEmails.length == 0 &&
            this.repeatedEmails.length == 0 &&
            !existingEmailOrPhone
        ) {
            try {
                //let allStaffsEmail = [];
                this.duplicateEmails = [];
                this.mlos = JSON.parse(JSON.stringify(this.mlos));
                let allMlos = [];

                this.mlos.forEach((ce, i) => {
                    if (Object.keys(ce).length > 0) {
                        let filledValues = [];
                        for (let key in ce) {
                            if (key != "createdOn" && ce[key]) {
                                filledValues.push(key);
                            }
                        }
                        if (filledValues.length > 0) {
                            ce["invitee"] = this.$userId;
                            allMlos.push(ce);
                        }
                    }
                });
                //BROKER PROCESSOR
                this.brokerProcessor = JSON.parse(JSON.stringify(this.brokerProcessor));
                let allBrokerProcessor = [];
                this.brokerProcessor.forEach((ce, i) => {
                    if (Object.keys(ce).length > 0) {
                        let filledValues = [];
                        for (let key in ce) {
                            if (key != "createdOn" && ce[key]) {
                                filledValues.push(key);
                            }
                        }
                        if (filledValues.length > 0) {
                            ce["addedBy"] = this.$userId;
                            ce['addedByUserType'] = this.$userType
                            allBrokerProcessor.push(ce);
                        }
                    }
                });

                // allMlos.forEach(e => {
                //   if (e.email != null) allStaffsEmail.push(e.email);
                // });
                this.adminStaffs = JSON.parse(JSON.stringify(this.adminStaffs));
                let allAdminStaffs = [];
                this.adminStaffs.forEach((ce, i) => {
                    if (Object.keys(ce).length > 0) {
                        let filledValues = [];
                        for (let key in ce) {
                            if (key != "createdOn" && ce[key]) {
                                filledValues.push(key);
                            }
                        }
                        if (filledValues.length > 0) {
                            ce["invitee"] = this.$userId;
                            allAdminStaffs.push(ce);
                        }
                    }
                });
                // allAdminStaffs.forEach(e => {
                //   if (e.email != null) allStaffsEmail.push(e.email);
                // });

                for (let i = 0; i < allMlos.length; i++) {
                    allMlos[i].brokerId = this.$brokerId;
                }
                for (let i = 0; i < allAdminStaffs.length; i++) {
                    allAdminStaffs[i].brokerId = this.$brokerId;
                }
                for (let i = 0; i < allBrokerProcessor.length; i++) {
                    allBrokerProcessor[i].addedBy = this.$brokerId;
                }
                let body = {
                    mlos: allMlos,
                    adminStaffs: allAdminStaffs,
                    mlosToDelete: this.mlosToDelete,
                    adminStaffsToDelete: this.adminStaffsToDelete,
                    brokerProcessor: allBrokerProcessor,
                    brokerProcessorToDelete: this.brokerProcessorToDelete
                };

                let response = await Axios.post(
                    BASE_API_URL + "broker/adminMloProcessorinfo", {
                        data: body,
                        brokerId: this.$brokerId
                    });

                if (response.data.type == "error") {
                    if (response.data.hasOwnProperty("duplicateEmails")) {
                        //this.$snotify.error(response.data.message);
                        this.duplicateEmails = response.data.duplicateEmails;
                    }
                } else {
                    await this.$store.dispatch("updatePreRequisite", 'management');
                    if (allAdminStaffs.length > 0)
                        this.$snotify.success(response.data.message);
                }

                this.inProcess = false;
            } catch (error) {
                console.log(error.response);
                this.inProcess = false;
            }
        } else {
            this.inProcess = false;
            this.$snotify.error("Please enter the Required fields.");
        }
    }
    //checking dubliate email and phone
    public async checkDublicacy(dstaffs, fieldType, staffType) {
        if (
            dstaffs[fieldType] &&
            dstaffs[fieldType] != undefined &&
            dstaffs[fieldType] != ""
        ) {
            let x = this.mlos.filter(emlo => emlo[fieldType] == dstaffs[fieldType]);
            if (staffType == "mlo" ? x.length > 1 : x.length > 0) {
                if (fieldType == "email") this.repeatedEmails.push(dstaffs.email);
            }
            let y = this.adminStaffs.filter(
                eadmin => eadmin[fieldType] == dstaffs[fieldType]
            );
            if (staffType == "adminStaff" ? y.length > 1 : y.length > 0) {
                if (fieldType == "email") this.repeatedEmails.push(dstaffs.email);
            }
            let processor = this.brokerProcessor.filter(
                brokerProcessor => brokerProcessor[fieldType] == dstaffs[fieldType]
            );
            if (
                staffType == "processor" ? processor.length > 1 : processor.length > 0
            ) {
                if (fieldType == "email") this.repeatedEmails.push(dstaffs.email);
            }
        }
    }

    public async checkInputName(staffType, index, fieldType = null) {
        if (fieldType && (fieldType == "email" || "phone")) {
            if (fieldType == "email") this.repeatedEmails = [];
            // if (fieldType == "email" || fieldType == "phone") {
            if (staffType == "mlo")
                this.mlos.forEach(dstaffs => {
                    // this.mlos[index]
                    this.checkDublicacy(dstaffs, fieldType, staffType);
                });
            if (staffType == "adminStaff")
                this.adminStaffs.forEach(dstaffs => {
                    // this.adminStaffs[index]
                    this.checkDublicacy(dstaffs, fieldType, staffType);
                });
            if (staffType == "processor")
                this.brokerProcessor.forEach(dstaffs => {
                    this.checkDublicacy(
                        // this.brokerProcessor[index],
                        dstaffs,
                        fieldType,
                        staffType
                    );
                });
            // }
        }
        let brokerStaffType = {
            mlo: "mlos",
            processor: "brokerProcessor",
            adminStaff: "adminStaffs"
        };
        this.validateEmailAndFirstName(brokerStaffType[staffType], index);
    }

    public validateEmailAndFirstName(userType, index) {
        try {
            let deleteUserArrayName = {
                mlos: "mlosToDelete",
                adminStaffs: "adminStaffsToDelete",
                brokerProcessor: "brokerProcessorToDelete"
            };

            let usersToDelete = deleteUserArrayName[userType];
            if (
                !this[userType][index].processorRelationship ||
                this[userType][index].processorRelationship == "" ||
                !this[userType][index].lastName ||
                this[userType][index].lastName == "" ||
                !this[userType][index].firstName ||
                this[userType][index].firstName == "" ||
                !this[userType][index].email ||
                this[userType][index].email == ""
            ) {
                let filledValues = [];
                for (let key in this[userType][index]) {
                    if (!this.arrayOfUnrequired.includes(key)) {
                        if (
                            this[userType][index][key] &&
                            this[userType][index][key] != ""
                        ) {
                            filledValues.push(key);
                        }
                    }
                }
                if (filledValues.length == 0) {
                    if (
                        this[userType][index].hasOwnProperty("userId") &&
                        this[userType][index].hasOwnProperty("_id")
                    ) {
                        this[usersToDelete].push(this[userType][index]["userId"]);
                    }
                } else {
                    if (
                        this[userType][index].hasOwnProperty("userId") &&
                        this[userType][index].hasOwnProperty("_id")
                    ) {
                        if (this[usersToDelete].includes(this[userType][index]["userId"])) {
                            this[usersToDelete].splice(
                                this[usersToDelete].indexOf(this[userType][index]["userId"]),
                                1
                            );
                        }
                    }
                }

                if (this.emptyFirstName.includes(userType + index)) {
                    if (
                        filledValues.length == 0 &&
                        (!this[userType][index].firstName ||
                            this[userType][index].firstName == "")
                    ) {
                        this.emptyFirstName.splice(
                            this.emptyFirstName.indexOf(userType + index),
                            1
                        );
                    }

                    if (
                        this[userType][index].firstName &&
                        this[userType][index].firstName != ""
                    ) {
                        this.emptyFirstName.splice(
                            this.emptyFirstName.indexOf(userType + index),
                            1
                        );
                    }
                } else {
                    if (
                        filledValues.length != 0 &&
                        (!this[userType][index].firstName ||
                            this[userType][index].firstName == "")
                    ) {
                        this.emptyFirstName.push(userType + index);
                    }
                }

                if (userType == "brokerProcessor") {
                    if (this.emptyLastName.includes(userType + index)) {
                        if (
                            filledValues.length == 0 &&
                            (!this[userType][index].lastName ||
                                this[userType][index].lastName == "")
                        ) {
                            this.emptyLastName.splice(
                                this.emptyLastName.indexOf(userType + index),
                                1
                            );
                        }
                        if (
                            this[userType][index].lastName &&
                            this[userType][index].lastName != ""
                        ) {
                            this.emptyLastName.splice(
                                this.emptyLastName.indexOf(userType + index),
                                1
                            );
                        }
                    } else {
                        if (
                            filledValues.length != 0 &&
                            (!this[userType][index].lastName ||
                                this[userType][index].lastName == "")
                        ) {
                            this.emptyLastName.push(userType + index);
                        }
                    }
                    if (this.notSelectRealtions.includes(userType + index)) {
                        if (
                            filledValues.length == 0 &&
                            (!this[userType][index].processorRelationship ||
                                this[userType][index].processorRelationship == "")
                        ) {
                            this.notSelectRealtions.splice(
                                this.notSelectRealtions.indexOf(userType + index),
                                1
                            );
                        }
                        if (
                            this[userType][index].processorRelationship &&
                            this[userType][index].processorRelationship != ""
                        ) {
                            this.notSelectRealtions.splice(
                                this.notSelectRealtions.indexOf(userType + index),
                                1
                            );
                        }
                    } else {
                        if (
                            filledValues.length != 0 &&
                            (!this[userType][index].processorRelationship ||
                                this[userType][index].processorRelationship == "")
                        ) {
                            this.notSelectRealtions.push(userType + index);
                        }
                    }
                }

                if (this.emptyEmails.includes(userType + index)) {
                    if (
                        filledValues.length == 0 &&
                        (!this[userType][index].email || this[userType][index].email == "")
                    ) {
                        this.emptyEmails.splice(
                            this.emptyEmails.indexOf(userType + index),
                            1
                        );
                    }

                    if (
                        this[userType][index].email &&
                        this[userType][index].email != ""
                    ) {
                        this.emptyEmails.splice(
                            this.emptyEmails.indexOf(userType + index),
                            1
                        );
                    }
                } else {
                    if (
                        filledValues.length != 0 &&
                        (!this[userType][index].email || this[userType][index].email == "")
                    ) {
                        this.emptyEmails.push(userType + index);
                    }
                }
            } else {
                if (
                    this[userType][index].hasOwnProperty("userId") &&
                    this[userType][index].hasOwnProperty("_id")
                ) {
                    if (this[usersToDelete].includes(this[userType][index]["userId"])) {
                        this[usersToDelete].splice(
                            this[usersToDelete].indexOf(this[userType][index]["userId"]),
                            1
                        );
                    }
                }

                if (this.emptyFirstName.includes(userType + index)) {
                    this.emptyFirstName.splice(
                        this.emptyFirstName.indexOf(userType + index),
                        1
                    );
                }

                if (this.emptyEmails.includes(userType + index)) {
                    this.emptyEmails.splice(
                        this.emptyEmails.indexOf(userType + index),
                        1
                    );
                }
                if (userType == "brokerProcessor") {
                    if (this.emptyLastName.includes(userType + index)) {
                        this.emptyLastName.splice(
                            this.emptyLastName.indexOf(userType + index),
                            1
                        );
                    }

                    if (this.notSelectRealtions.includes(userType + index)) {
                        this.notSelectRealtions.splice(
                            this.notSelectRealtions.indexOf(userType + index),
                            1
                        );
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    public async addPerson(type: string) {
        if (type == "mlos") {
            let mlo = new MLO();
            mlo["createdOn"] = new Date().toISOString();
            this.mlos.push(mlo);
        } else if (type == "processor") {
            let processor = new wemlouserdetail();
            processor["createdOn"] = new Date().toISOString();
            this.brokerProcessor.push(processor);
        } else {
            let as = new AdminStaff();
            as["createdOn"] = new Date().toISOString();
            this.adminStaffs.push(as);
        }
    }

    public async removePerson(type: string, index: number) {
        if (type == "mlos") {
            if (
                this.mlos[index].hasOwnProperty("userId") &&
                this.mlos[index].hasOwnProperty("_id")
            ) {
                this.mlosToDelete.push(this.mlos[index]["userId"]);
            }
            this.mlos.splice(index, 1);
        } else if (type == "processor") {
            if (
                this.brokerProcessor[index].hasOwnProperty("userId") &&
                this.brokerProcessor[index].hasOwnProperty("_id")
            ) {
                this.brokerProcessorToDelete.push(
                    this.brokerProcessor[index]["userId"]
                );
            }
            this.brokerProcessor.splice(index, 1);
        } else {
            if (
                this.adminStaffs[index].hasOwnProperty("userId") &&
                this.adminStaffs[index].hasOwnProperty("_id")
            ) {
                this.adminStaffsToDelete.push(this.adminStaffs[index]["userId"]);
            }
            this.adminStaffs.splice(index, 1);
        }
    }

    public async goPrevious() {
        //this.previousInProcess = JSON.parse(JSON.stringify(this.previousInProcess));
        this.previousInProcess = true;
        await this.modifyPreRequisite('agreement');
        this.previousInProcess = false;
    }

    public async getBrokerStaffs() {
        try {
            let response = await Axios.get(
                BASE_API_URL + "broker/getBrokerStaffsdata/" + this.$brokerId);
            if (response.data) {
                if (response.data.mlos.length > 0) {
                    this.mlos = response.data.mlos;
                } else {
                    this.addPerson("mlos");
                }
                if (response.data.adminStaffs.length > 0) {
                    this.adminStaffs = response.data.adminStaffs;
                } else {
                    this.addPerson("adminStaffs");
                }
            }
        } catch (error) {
            console.log(error.response);
        }
    }
    public async getBrokerProcessor() {
        try {
            let response = await Axios.get(
                BASE_API_URL + "broker/getBrokerProcessor", {
                    params: {
                        userId: this.$userId
                    }
                }
            );
            if (response.data) {
                if (response.data.brokerProcessor.length > 0)
                    this.brokerProcessor = response.data.brokerProcessor;
                else this.addPerson("processor");
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * To get the value of activateWemloProcessing from broker collection
     */
    public async getWemloProcessing() {
        try {
            let response = await Axios.post(
                BASE_API_URL + "broker/getWemloProcessing", {
                    userId: this.$userId
                });
            this.activateWemPro = response.data.activateWemloProcessing;
        } catch (error) {
            console.log(error, "getWemloProcessing");
        }
    }
    /**
     * to activate the wemlo processing via setting true in activateWemloProcessing
     */
    public async activateProcessing(val) {
        try {
            let sure = false;
            let msg = !val ?
                "Are you sure you want to Deactivate Wemlo Processing?" :
                "Are you sure you want to Activate Wemlo Processing?";
            await this.$dialog
                .confirm(msg)
                .then(e => {
                    sure = true;
                })
                .catch(f => {
                    sure = false;
                    this.activateWemPro = !this.activateWemPro;
                    this.$snotify.info("No changes");
                });
            if (sure) {
                this.activateWemPro = val;
                this.activateWemloProcessing({
                    wemloProcessor: this.activateWemPro,
                    userId: this.$userId
                });

                // let response = await Axios.post(
                //   BASE_API_URL + "broker/activateWemloProcessing",
                //   {
                //     wemloProcessor: this.activateWemPro,
                //     userId: this.$userId
                //   },
                //   {
                //
                //   }
                // );
                // this.$snotify.success("Updated");
            }
        } catch (error) {
            console.log(error, "activateProcessing");
        }
    }
    async mounted() {
        await this.getWemloProcessing();
        this.getBrokerProcessor();
        this.getBrokerStaffs();
        if (this.$loader) {
            this.$loader.hide();
        }
    }
}
