


import {
    Component,
    Vue
} from "vue-property-decorator";
import Axios from "axios";
import {
    BASE_API_URL
} from "@/config";
import {
    LOSModel
} from "@/models/vendor-los.model";
import {
    CreditVendorModel
} from "@/models/vendor-credit.model";
import {
    AMCVendorModel
} from "@/models/vendor-amc.model";
import {
    AUSVendorModel
} from "@/models/vendor-aus.model";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
import {
    PreRequisiteModel
} from "@/models/pre-requisite.model";
import {
    mixins
} from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import {
    JsonAdaptor
} from "@syncfusion/ej2-data";
import {
    getNextOnboardingStepId,
    isPriorStepsComplete
} from "@/constants";
@Component({
    components: {
        PreRequisiteHeader
    }
})
export default class VendorInfo extends mixins(CommonMixin) {
    //public vendors = {};
    public brokerVendors = [];
    public preRequisite: any = new PreRequisiteModel();
    public inProcess: boolean = false;
    public previousInProcess: boolean = false;
    public brokerVendorType: string = "";
    public message: string = "";
    public currentRoute: string = "";
    public hasPreviousData: boolean = false;
    public vendorsToRemove: any = [];
    get completedPreRequisite() {
        return this.$store.state.sessionObject.userInfo.completedPreRequisites;
    }

    get lastPage() {
        let user = this.$store.state.sessionObject.userInfo;
        if (user) {
            return user.completedPreRequisites;
        } else {
            return -1;
        }
    }

    public async validateAndSubmit() {
        let nextPrerequisite = getNextOnboardingStepId(this.$store, 2);
        try {
            this.inProcess = true;
            let emptyValueSets = [];
            let formValid = await this.$validator.validateAll();
            if (!formValid) {
                this.inProcess = false;
                return;
            }
            this.brokerVendors.forEach(e => {
                delete e.message;
                if (e.multiEntry) {
                    delete e.multiEntry;
                }
            });

            let vendorsToRemove = this.vendorsToRemove;

            if (this.brokerVendorType == "AUS Vendor") {
                let emptyFannieMae = false;
                let emptyFreddieMac = false;
                if (
                    this.brokerVendors[0].fannieMae.userName == "" &&
                    this.brokerVendors[0].fannieMae.password == ""
                ) {
                    emptyFannieMae = true;
                    if (this.brokerVendors[0].fannieMae._id) {
                        vendorsToRemove.push(this.brokerVendors[0].fannieMae._id);
                    }
                }
                if (
                    this.brokerVendors[0].freddieMac.userName == "" &&
                    this.brokerVendors[0].freddieMac.password == ""
                ) {
                    emptyFreddieMac = true;
                    if (this.brokerVendors[0].fannieMae._id) {
                        vendorsToRemove.push(this.brokerVendors[0].freddieMac._id);
                    }
                }
                if (emptyFannieMae == true && emptyFreddieMac == true) {
                    this.brokerVendors.pop();
                }
            } else {
                this.brokerVendors.forEach((e, i) => {
                    let fields = [];
                    for (var key in e) {
                        if (e.hasOwnProperty(key)) {
                            if (
                                ![
                                    "_id",
                                    "vendorType",
                                    "message",
                                    "multiEntry",
                                    "brokerVendorReferenceId"
                                ].includes(key) &&
                                e[key] != ""
                            ) {
                                fields.push(e[key]);
                            }
                        }
                    }

                    if (fields.length == 0) {
                        emptyValueSets.push(i);
                    }
                });

                this.brokerVendors = this.brokerVendors.filter((bv, i) => {
                    if (
                        this.$userType == "MLO" &&
                        bv.brokerVendorReferenceId &&
                        !bv._id
                    ) {
                        if (bv["userName"] || bv["password"]) return bv;
                    } else if (
                        this.$userType == "MLO" &&
                        bv.brokerVendorReferenceId &&
                        bv._id
                    ) {
                        if (
                            (!bv["userName"] || bv["userName"] == "") &&
                            (!bv["password"] || bv["password"] == "")
                        ) {
                            vendorsToRemove.push(bv._id);
                        } else {
                            return bv;
                        }
                    } else {
                        if (emptyValueSets.includes(i)) {
                            if (bv.hasOwnProperty("_id")) {
                                vendorsToRemove.push(bv._id);
                            }
                        } else {
                            return bv;
                        }
                    }
                });
            }

            let brokerVendors = this.brokerVendors;
            if (this.brokerVendors.length == 0) {
                this.addVendors();
            }

            if (this.brokerVendors.length == 0 && vendorsToRemove.length == 0) {
                await this.$store.dispatch("updatePreRequisiteOnServer", {
                    userType: this.$userType,
                    userId: this.$userId,
                    brokerId: this.$brokerId,
                    num: nextPrerequisite
                });
                await this.$store.dispatch(
                    "updatePreRequisite",
                    nextPrerequisite
                );
                if (this.$userType == "MLO" && isPriorStepsComplete('aus', nextPrerequisite)) {
                    this.$router.push("/mlo/dashboard");
                } else {
                    this.brokerVendors = [];
                    this.setBrokerVendors();
                    this.getVendors();
                    this.inProcess = false;
                }
            } else {
                let response = await Axios.post(
                    BASE_API_URL + "broker/saveBrokerVendors", {
                        vendorType: this.brokerVendorType,
                        vendors: brokerVendors,
                        brokerId: this.$brokerId,
                        userId: this.$userId,
                        userType: this.$userType,
                        completedPreRequisite: nextPrerequisite,
                        vendorsToRemove: vendorsToRemove
                    });
                if (response.data.status == "success") {
                    await this.$store.dispatch(
                        "updatePreRequisite",
                        getNextOnboardingStepId(this.$store, 2)
                    );
                    if (this.$userType == "MLO" && isPriorStepsComplete('aus', nextPrerequisite)) {
                        this.$router.push("/mlo/dashboard");
                    } else {
                        this.brokerVendors = [];
                        this.setBrokerVendors();
                        this.getVendors();
                        this.$snotify.success(response.data.message);
                    }
                    // this.$router.push({ name: this.preRequisite.steps[nextStep] });
                }
                this.inProcess = false;
            }
        } catch (error) {
            console.log(error.response);
            this.inProcess = false;
        }
    }

    public async getStepName(value) {
        let step: string = "";
        for (var key in this.preRequisite.steps) {
            if (this.preRequisite.steps[key] == value) {
                step = key;
            }
        }
        return step;
    }

    public async addVendors() {
        // let lastPage: string = this.$route.name;
        let vendorData: any = {};
        // if (this.lastPage == 1) {
        //   vendorData = new LOSModel();
        // } else 
        if (this.lastPage == 'creditVendor') {
            vendorData = new CreditVendorModel();
        } else if (this.lastPage == 'appraisal') {
            vendorData = new AMCVendorModel();
        } else if (this.lastPage == 'aus') {
            vendorData = new AUSVendorModel();
        }
        await this.brokerVendors.push(vendorData);
    }

    public async removeVendors(index: number) {
        let vendor = this.brokerVendors[index];
        if (vendor._id) this.vendorsToRemove.push(vendor._id);
        this.brokerVendors.splice(index, 1);
    }

    public async setBrokerVendors(lastPage ? ) {
        // let routeName: string = this.$route.name;
        let vendorData: any = {};
        // if (this.lastPage == 1) {
        //   vendorData = new LOSModel();
        // } else
        if (this.lastPage == "creditVendor") {
            vendorData = new CreditVendorModel();
        } else if (this.lastPage == "appraisal") {
            vendorData = new AMCVendorModel();
        } else if (this.lastPage == 'aus') {
            vendorData = new AUSVendorModel();
        }
        this.brokerVendorType = vendorData.vendorType;
        this.message = vendorData.message;
        this.brokerVendors.push(vendorData);
        return;
    }

    // getVendors() methods:-To get Vendro information on click of previous button
    public async getVendors() {
        try {
            let response = await Axios.get(
                BASE_API_URL +
                "broker/getVendor/" +
                this.$userId +
                "/" +
                this.$userType +
                "/" +
                this.$brokerId +
                "/" +
                this.brokerVendorType);

            if (this.brokerVendorType == "AUS Vendor") {
                if (response.data.length > 0) {
                    if (this.$userType == "MLO") {
                        response.data.forEach((e, i) => {
                            if (e.addedBy == this.$brokerId) {
                                e.userName = null;
                                e.password = null;
                                e.brokerVendorReferenceId = e._id;
                                delete e._id;
                            }
                        });
                    }
                    response.data.forEach((e, i) => {
                        if (e.vendorName == "Fannie Mae") {
                            Object.keys(this.brokerVendors[0].fannieMae).forEach(key => {
                                if (e.hasOwnProperty(key)) {
                                    this.brokerVendors[0]["fannieMae"][key] = e[key];
                                }
                            });
                            if (e.hasOwnProperty("_id"))
                                this.brokerVendors[0]["fannieMae"]["_id"] = e["_id"];
                        }

                        if (e.vendorName == "Freddie Mac") {
                            Object.keys(this.brokerVendors[0].freddieMac).forEach(key => {
                                if (e.hasOwnProperty(key)) {
                                    this.brokerVendors[0]["freddieMac"][key] = e[key];
                                }
                            });
                            if (e.hasOwnProperty("_id"))
                                this.brokerVendors[0]["freddieMac"]["_id"] = e["_id"];
                        }
                    });
                }
            } else {
                if (response.data.length > 0) {
                    response.data = JSON.parse(JSON.stringify(response.data));

                    let modifiedBrokerVendors = [];
                    if (this.$userType == "MLO") {
                        response.data.forEach(element => {
                            if (element.brokerVendorReferenceId) {
                                modifiedBrokerVendors.push(element.brokerVendorReferenceId);
                            }
                        });

                        response.data = response.data.filter(
                            ele => !modifiedBrokerVendors.includes(ele._id)
                        );
                    }

                    let promise1 = [];
                    for (let index = 0; index < response.data.length - 1; index++) {
                        //Since one data is already entered through setBrokerVendors() function
                        promise1.push(this.addVendors());
                    }
                    await Promise.all(promise1);

                    if (this.$userType == "MLO") {
                        response.data = JSON.parse(JSON.stringify(response.data));
                        response.data.forEach(async (e, i) => {
                            if (e.addedBy == this.$brokerId) {
                                e.brokerVendorReferenceId = e._id;
                                delete e._id;
                                e.userName = null;
                                e.password = null;
                                Object.keys(this.brokerVendors[i]).forEach(key => {
                                    if (e.hasOwnProperty(key)) {
                                        this.brokerVendors[i][key] = e[key];
                                    }
                                });
                                response.data = JSON.parse(JSON.stringify(response.data));
                            } else {
                                Object.keys(this.brokerVendors[i]).forEach(key => {
                                    if (e.hasOwnProperty(key)) {
                                        this.brokerVendors[i][key] = e[key];
                                    }
                                });
                                this.brokerVendors[i]["_id"] = e._id;
                            }
                        });
                    } else {
                        response.data.forEach(async (e, i) => {
                            Object.keys(this.brokerVendors[i]).forEach(key => {
                                if (e.hasOwnProperty(key)) {
                                    this.brokerVendors[i][key] = e[key];
                                }
                            });
                            this.brokerVendors[i]["_id"] = e._id;
                        });
                    }
                }
            }
            this.vendorsToRemove = [];
        } catch (error) {
            console.log(error, "error");
        }
    }

    public async goPrevious() {
        this.previousInProcess = true;
        let prevPrerequisite = getNextOnboardingStepId(this.$store, 1);
        await this.modifyPreRequisite(prevPrerequisite);
        this.brokerVendors = [];
        this.setBrokerVendors();
        this.getVendors();
        this.previousInProcess = false;
    }
    async mounted() {
        let lastPage: string = this.$route.name;
        await this.setBrokerVendors(this.lastPage);
        this.getVendors();
        if (this.$loader) {
            this.$loader.hide();
        }
    }
}
